<template>
  <page-content>
    <div class="device-message" hidden>
      <div class="device-message-content">
        <div class="device-message-img">
          <div>用户总数</div>
          <img src="../../../static/img/dataUrlImage_1.png" style="width:32px;height:36px;"/>
        </div>
        <div class="device-message-num" style="color:#FFBF43">{{ userTotal.total }}</div>
      </div>
      <div class="device-message-content">
        <div class="device-message-img">
          <div>设备用户比</div>
          <img src="../../../static/img/dataUrlImage_0.png" style="width:32px;height:36px;"/>
        </div>
        <div class="device-message-num" style="color:#FF934A">{{ userTotal.totalRatio.toFixed(2)}}</div>
      </div>
      <div class="device-message-content">
        <div class="device-message-img">
          <div>今日新增</div>
          <img src="../../../static/img/dataUrlImage_2.png" style="width:32px;height:36px;"/>
        </div>
        <div class="device-message-num" style="color:#41A5F5">{{ userTotal.totalToday }}</div>
      </div>
      <div class="device-message-content">
        <div class="device-message-img">
          <div>最近7天新增</div>
          <img src="../../../static/img/dataUrlImage_3.png" style="width:32px;height:36px;"/>
        </div>
        <div class="device-message-num" style="color:#29CDBC">{{ userTotal.totalWeek }}</div>
      </div>
    </div>

    <common-table
      ref="table"
      path="appUser"
      :columns="columns">

      <template slot="avatar" slot-scope="{record}">
        <a-avatar shape="square" :size="35" :src="record.avatarUrl">{{record.nickName}}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <a-icon
          v-hasPermission="['treatRecord:view']"
          type="switcher"
          theme="twoTone"
          twoToneColor="#4a9ff5"
          @click="UNKTUserTreatRecord(record)"
          title="治疗数据统计"
        ></a-icon>
      </template>

    </common-table>
  </page-content>
</template>
<script>

  export default {
    data () {
      return {

        userTotal: {
          total: 0,
          totalRatio: 0,
          totalToday: 0,
          totalWeek: 0
        },

      }
    },
    computed: {
      columns () {

        return [
          {
            title: '头像',
            dataIndex: 'avatarUrl',
            scopedSlots: {customRender: 'avatar'}
          },
          {
            title: '用户名',
            dataIndex: 'nickName'
          },
          // {
          //   title: '性别',
          //   dataIndex: 'gender',
          //   customRender: (text, row, index) => {
          //     switch (text) {
          //       case 0:
          //         return '男'
          //       case 1:
          //         return '女'
          //       case 2:
          //         return '保密'
          //       default:
          //         return text
          //     }
          //   },
          //   filters: [
          //     {text: '男', value: '0'},
          //     {text: '女', value: '1'},
          //     {text: '保密', value: '2'}
          //   ],
          //   filterMultiple: false,
          // },
          {
            title: '手机号',
            dataIndex: 'phone'
          },
          // {
          //   title: '邮箱',
          //   dataIndex: 'email'
          // },
          {
            title: '注册时间',
            dataIndex: 'gmtCreate',
            customRender: (text, row, index) => {
              return this.dateFormat(text)
            },
            sorter: true,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
            fixed: 'right',
            width: 80
          }
        ]
      }
    },
    created () {
      this.$get('appUser/overview', {}).then((r) => {
        this.userTotal = r.data
      })
    },
    methods: {
      dateFormat (time) {
        var now = new Date(time)
        var year = now.getFullYear()
        var month = this.fullFormat(now.getMonth() + 1)
        var day = this.fullFormat(now.getDate())
        var hour = this.fullFormat(now.getHours())
        var minute = this.fullFormat(now.getMinutes())
        var seconds = this.fullFormat(now.getSeconds())
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + seconds
      },
      fullFormat (time) {
        var string = time.toString()
        if (string.length === 1) {
          string = '0' + string
        }
        return string
      },

      view (record) {
        this.$router.push(`/user/info/${record.identityId}`)
      },
      UNKTUserTreatRecord (record) {
        this.$router.push(`/user/treatRecord/${record.identityId}`)
      },

      getList () {
        this.$refs.table.getData()
      }
    }
  }
</script>
<style lang="less" scoped>


  .device-message {
    height: 92px;
    width: 100%;
    position: relative;
    margin-bottom: 12px;
  }

  .device-message-content {
    height: 92px;
    width: 23.889%;
    box-shadow: 0 0 1px 0px rgb(206, 206, 206);
    float: left;
    margin-left: 1.48%;
    background-color: #fff;
    position: relative;
  }

  .device-message-content:first-child {
    margin-left: 0px;
  }

  .device-message-num {
    float: right;
    font-size: 28px;
    margin-right: 16px;
    line-height: 92px;
  }

  .device-message-img {
    float: left;
    font-size: 12px;
    color: #333;
    height: 72px;
    width: 90px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-left: 24px;
  }

  .device-message-img > div {
    margin-bottom: 14px;
  }
</style>
